window.dataLayer = window.dataLayer || [];
function gtag() {
  dataLayer.push(arguments);
}
gtag('js', new Date());

if (window.gtagAdsId) {
  gtag('config', window.gtagAdsId);
}

if (window.gtagGA4Id) {
  gtag('config', window.gtagGA4Id);
}
